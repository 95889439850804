import React from "react";
import { Handle, Position } from "@xyflow/react";
import { Typography, Avatar, Flex, Form, Select, Input, Popover } from "antd";
import { MODELS, TOOLS } from "../../../../../../components/forms/forms/enum";
import TitleDivider from "../TitleDivider/TitleDivider";
import FeatherIcon from "feather-icons-react";
import useWorkflowActions from "../../../../../../context/actions/useWorkflowActions";
import Chat from "../Chat/Chat";
import { useOnClickOutside } from "usehooks-ts";

import "./StandardNode.scss";

const { Option } = Select;
const { Text } = Typography;

const StandardNode = (props) => {
  const { data, isConnectable, selected, style = {} } = props;
  const { onSelectAgent } = useWorkflowActions();
  const [form] = Form.useForm();
  const [openModel, setOpenModel] = React.useState(false);
  const [openTools, setOpenTools] = React.useState(false);
  const [openInput, setOpenInput] = React.useState(false);
  const nodeActionsRef = React.useRef(null);

  const classNames = ["rf-standard-node"];
  const nodeDomId = `${data.id}-actions`;
  const isStarting = data?.startingNode;

  const handleClickOutside = () => {
    setOpenInput(false);
  };

  useOnClickOutside(nodeActionsRef, handleClickOutside);

  if (selected) classNames.push("selected-node");
  if (isStarting) classNames.push("starting-node");

  return (
    <div
      className={classNames.join(" ")}
      style={style}
      ref={nodeActionsRef}
    >
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Flex
        className="standard-node"
        vertical={true}
      >
        <Flex
          className="standard-node--header"
          justify="space-between"
          align="center"
        >
          <Flex
            align="center"
            gap={6}
          >
            <Avatar
              size={26}
              className="agent-avatar"
              icon={
                <FeatherIcon
                  icon={"user"}
                  size={16}
                />
              }
            />
            <Text strong>{data.name}</Text>
          </Flex>
          <Flex
            className="node-actions"
            id={nodeDomId}
          >
            {isStarting && (
              <Popover
                content={
                  <Chat
                    agentId={data.id}
                    openInput={openInput}
                    setOpenInput={setOpenInput}
                  />
                }
                getPopupContainer={() => document.getElementById(nodeDomId)}
                destroyTooltipOnHide={true}
                title="Input"
                trigger="click"
                open={openInput}
                onClick={() => {
                  setOpenInput(true);
                }}
              >
                <FeatherIcon
                  icon={"message-square"}
                  size={16}
                  style={{ cursor: "pointer" }}
                  // onClick={() => onSelectAgent(data)}
                />
              </Popover>
            )}

            <FeatherIcon
              icon={"edit-3"}
              size={16}
              style={{ cursor: "pointer" }}
              onClick={() => onSelectAgent(data)}
            />
          </Flex>
        </Flex>
        <TitleDivider
          title="Attributes"
          isAlt={data.startingNode}
        />
        <div className="standard-node--body">
          <Form
            name={data.name}
            layout="vertical"
            form={form}
            initialValues={data}
          >
            <Form.Item
              label="Model"
              name="model"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                size="small"
                options={MODELS}
                value={data.model}
                open={openModel}
                onBlur={() => setOpenModel(false)}
                onFocus={() => setOpenModel(true)}
              >
                {MODELS.map((item, i) => (
                  <Option
                    key={i}
                    value={item.value}
                  >
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Prompt"
              name="prompt"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.TextArea
                size="small"
                value={data.prompt}
                rows={4}
              />
            </Form.Item>
            <Form.Item
              label="Tools"
              name="tools"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                mode="multiple"
                size="small"
                value={data.tools}
                options={TOOLS}
                open={openTools}
                onBlur={() => setOpenTools(false)}
                onFocus={() => setOpenTools(true)}
              />
            </Form.Item>
          </Form>
        </div>
      </Flex>

      <Handle
        type="source"
        position={Position.Right}
        id="b"
        // style={{ bottom: 10, top: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      />
    </div>
  );
};

export default StandardNode;
