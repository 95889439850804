import React from "react";
import { Input, Space, Button, message } from "antd";
import useChatStore from "../../../../../../context/stores/useChatStore";

const CHATKEY = "tktech-chat-inputs";

export default function Chat({ agentId, setOpenInput }) {
  const { payloadMessages, setPayloadMessages } = useChatStore();
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    let inputMap = localStorage.getItem(CHATKEY);
    if (inputMap) {
      inputMap = JSON.parse(inputMap);
      if (inputMap[agentId]) {
        setValue(inputMap[agentId]);
      }
    }
  }, []);

  const onSave = () => {
    setPayloadMessages({
      ...payloadMessages,
      [agentId]: value,
    });
    let inputMap = localStorage.getItem(CHATKEY);
    if (inputMap) {
      inputMap = JSON.parse(inputMap);
      inputMap[agentId] = value;
      localStorage.setItem(CHATKEY, JSON.stringify(inputMap));
    } else {
      localStorage.setItem(CHATKEY, JSON.stringify({ [agentId]: value }));
    }
    setOpenInput(false);
    message.success(`Input saved`);
  };

  React.useEffect(() => {
    if (payloadMessages[agentId]) {
      setValue(payloadMessages[agentId]);
    }
  }, [agentId, payloadMessages]);

  return (
    <Space.Compact style={{ minWidth: 400 }}>
      <Input.TextArea
        value={value}
        autoSize={true}
        onChange={(e) => setValue(e.target.value)}
      />
      <Button
        onClick={onSave}
        type="primary"
      >
        Save
      </Button>
    </Space.Compact>
  );
}
