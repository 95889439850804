import React from "react";
import { App } from "antd";

export default function useErrorHandler() {
  const { message } = App.useApp();

  const handleError = (error) => {
    console.log({ errorResp: error?.response });
    if (error?.response?.data?.errors) {
      error.response.data.errors.forEach((error) => {
        console.log("error: ", error.response.data.errorr);
        message.error(error.message);
      });
      return error;
    } else if (error?.response?.data?.error) {
      console.log("error: ", error.response.data.error);
      message.error(error.response.data.error);
      return error;
    }

    message.error("Error (UPDATE ME)");
    return error;
  };

  return {
    handleError,
  };
}
